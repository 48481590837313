type Contracts = {
  [network: string]: {
    [contract: string]: string;
  };
};

export const contractAddresses: Contracts = {
  'base_production': {
    'legacyRamp': '0xB084f36C5B7193af8Dd17025b36FBe2DD496a06f',
    'legacyNft': '0xaB6b0b5666DC2C8357fdcD8A1E96973932dd1545',
    'usdc': '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    'venmoRamp': '0xbcE0B9e082F6619411dd8d029DFf8116BA36A76D',
    'venmoSendProcessor': '0x170Cfa26a1fC6868dC45B251e7a482188A84A1d4',
    'venmoRegistrationProcessor': '0x209E0fa47FAbdb656B8b4bb8fBA01322e9d5cf00',
    'venmoNft': '0x4281cee3ae43d19a2cf9a6002cb7ea90869ad4d5',
    'hdfcRamp': '0xf3c9a6CA0DF1950a62ea868704678b1e8C34918a',
    'hdfcSendProcessor': '0x03eB0ba3f7A4451C41278Af74E8384079Ae40170',
    'hdfcRegistrationProcessor': '0xD2B4CcA64Fc4B7588D6546780fEdb4c71A4b75D6',
    'hdfcNft': '0x94874b3a294becc3dea2c320c300d2d2a4b25451',
    'garantiRamp': '0x4Edc42B8925CF307C3DD5e7Fd9a3B04453A3e37d',
    'garantiSendProcessor': '0x29666c1dB00852c5378d4299ae24B6CB8081057f',
    'garantiRegistrationProcessor': '0x4893d6b0A9dc0B11f373f673bC2bCfAE74c0a1f9',
    'garantiNft': '0xB4739590a00b47AF7461AbBC013b9047f66Dd678',
    'revolutRamp': '0xeb33C544dBd9a487de0522fE886fc38D66887533',
    'revolutAccountRegistry': '0x969FD0eD23A44756678af779FD0206aE123261B2',
    'revolutSendProcessor': '0xaD7cC2A3a83Fb13F3A82D6cC31928d3E91fF25cc',
    'revolutAccountRegistrationProcessor': '0x7699Fce34011688a67cC44af4C76d1B38BA1a3a8',
    'revolutNft': '0x547bfffc91603ceff30edc308b5a16a178dae8d3',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'
  },
  'base_staging': {
    'legacyRamp': '0xa08d9952196ABECB2BaCD14188093314053f6335',
    'legacyNft': '0x516Aba305CD1fd36c540D9EDc679e165EAB89e9F',
    'usdc': '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    'venmoRamp': '0x80e5aB2921e23192B2454f6a386Fd7032dad932E',
    'venmoSendProcessor': '0xd654400e33c54E4E811b979b8D14F9fc9543683C',
    'venmoRegistrationProcessor': '0x050F07A4E7C0F4Ee6Cc8E8EdC5B694F9Fdd1e769',
    'venmoNft': '0xc0c2ad120c63b3c7d7e9c555352e42c2fc8bb50b',
    'hdfcRamp': '0xc137d22fa93316Df55b5F896F5180c722D02b01D',
    'hdfcSendProcessor': '0x3bCC71916d9d2BB5F52Fdb386814bd84C9312A04',
    'hdfcRegistrationProcessor': '0xC8d7C6a5a8B2158012FB679DB1c3d7fbC1cC2980',
    'hdfcNft': '0x6b64BC61cd03cdD7Ac3aF6Ad6A02977265d21ecB',
    'garantiRamp': '0x27a6Ecb917B4BbCb6450f50Fa873510Ac3077171',
    'garantiSendProcessor': '0x1048dd39f95aBE7712291752B63f61BD397c695E',
    'garantiRegistrationProcessor': '0x6E6eAc7e8995fb6d44aE5E559874B8C969010293',
    'garantiNft': '',
    'revolutRamp': '0x84268621B45C24A7A2A3fb700156B0D5Ac75Ee0b',
    'revolutAccountRegistry': '0x44115b15Ff0Db10702DFC72Cb2fd3179215623df',
    'revolutSendProcessor': '0xC2039e46f59cAC1ddC9a045563946A08D58d6f6A',
    'revolutAccountRegistrationProcessor': '0x96fa6C854F35665c0B5C6365c64a9EECa5793411',
    'revolutNft': '0x7f846bbf546dd56ed262969bc7b02254a0b15463',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'
  },
  'sepolia_staging': {
    'legacyRamp': '0x75422735DD94dfD04b7ef5D7044Aba0ce4E3a7A6',
    'legacyNft': '',
    'usdc': '0xBBA5C9bd54a4293f4261b38e5aD41820eC41ed86',
    'venmoRamp': '0x38637CD256d70994f2d5533BEAfe52eEfC2a96Ab',
    'venmoSendProcessor': '0x84ac07EfC0c7093416aCd6189a600AD479CFA045',
    'venmoRegistrationProcessor': '0x8374d6e81363fE432F98E46E8A6Fe0873e526FB8',
    'venmoNft': '0x73ca27fd61afa3823046f9f9101b17a0cfa5c1ad',
    'hdfcRamp': '0xb4A7486b0EFa264D5FC6A8181bfc7A150cD57849',
    'hdfcSendProcessor': '0x668109Fb6c6D2a563F9b7FB3dA3367CCb726B4af',
    'hdfcRegistrationProcessor': '0xE5e50A9F071DF9185183c6523c9a4162eB278583',
    'hdfcNft': '0x1bb5f399a50050d76ebffb52ae8a526493ef8916',
    'garantiRamp': '0xEfBDf422FA81071A04D2f89A70A0D938FEFA0795',
    'garantiSendProcessor': '0xd30087458186424C12BEAEbf1167142284E33437',
    'garantiRegistrationProcessor': '0xFbBdAa7354fB1d40B48130F026e6DD371474dB8D',
    'garantiNft': '0x64940a0906d4386e2089a40153D856EEA070Ad88',
    'revolutRamp': '0x11E267C4861BBF3AA3368Bb78dE2Cdb380c9000D',
    'revolutAccountRegistry': '0x4b6db2939E09A642afb90cEd37126213aBbA0f4F',
    'revolutSendProcessor': '0xB3017240379888Ba2022351Ab6fd4fB0b8FF5e23',
    'revolutAccountRegistrationProcessor': '0x57068ef4f64734Ef4f84705E38A00176E53f0000',
    'revolutNft': '0x3380881753f741FFf10833EeD3addaa859f81786',
    'socketBridge': '',
    'lifiBridge': '',
  },
  'localhardhat': {
    'legacyRamp': '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    'legacyNft': '',
    'usdc': '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    'venmoRamp': '0xa85233C63b9Ee964Add6F2cffe00Fd84eb32338f',
    'venmoSendProcessor': '0x09635F643e140090A9A8Dcd712eD6285858ceBef',
    'venmoRegistrationProcessor': '0x7a2088a1bFc9d81c55368AE168C2C02570cB814F',
    'venmoNft': '',
    'hdfcRamp': '0x0B306BF915C4d645ff596e518fAf3F9669b97016',
    'hdfcSendProcessor': '0x68B1D87F95878fE05B998F19b66F4baba5De1aed',
    'hdfcRegistrationProcessor': '0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE',
    'hdfcNft': '',
    'garantiRamp': '0x9E545E3C0baAB3E08CdfD552C960A1050f373042',
    'garantiSendProcessor': '0xf5059a5D33d5853360D16C683c16e67980206f36',
    'garantiRegistrationProcessor': '0x851356ae760d987E095750cCeb3bC6014560891C',
    'garantiNft': '',
    'revolutRamp': '0x0E801D84Fa97b50751Dbf25036d067dCf18858bF',
    'revolutAccountRegistry': '0x8f86403A4DE0BB5791fa46B8e795C547942fE4Cf',
    'revolutSendProcessor': '0x5eb3Bc0a489C5A8288765d2336659EbCA68FCd00',
    'revolutAccountRegistrationProcessor': '0x9d4454B023096f34B160D6B654540c56A1F81688',
    'revolutNft': '',
    'socketBridge': '',
    'lifiBridge': '',
  },
};

export const blockExplorerUrls: { [network: string]: string } = {
  'hardhat': 'https://etherscan.io',
  'sepolia': 'https://sepolia.etherscan.io/',
  'base': 'https://basescan.org'
};
